import {useEffect} from 'react';
import Product from './pages/Product';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Settings from './pages/Settings';
import Submit from './pages/Submit';
import Brands from './pages/Brands';
import Brand from './pages/Brand';
import Review from './pages/Review';

import {NotificationContainer} from 'react-notifications';

function App() {
  if(window.location.pathname === "/jei"){
    window.location.replace("https://www.youtube.com/@JeiDrips")
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/product" element={<h1>Product Not Found 🤔</h1>} />
        <Route path="/settings" element={<Settings/>} />
        <Route path="/submit" element={<Submit/>} />
        <Route path="/brands" element={<Brands/>} />
        <Route path="/brand/:name" element={<Brand/>} />
        <Route path="/brand" element={<h1>Brand Not Found 🤔</h1>} />
        <Route path="/404" element={<h1>404: Not Found</h1>} />
        <Route path="/500" element={<h1>500: Internal Server Error</h1>} />
        <Route path="/review" element={<Review/>} />
        <Route path="*" element={<h1>404: Not Found</h1>} />
      </Routes>
      <NotificationContainer/>
    </BrowserRouter>
  );
}

export default App;
