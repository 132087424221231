const API_URL = 'https://replandapi.onrender.com';

export async function getDetails(id){
    //let details = {id: id, url: "https://item.taobao.com/item.htm?id=671475758312", brand: "Suprem", name: "Some cool hoodie or sum", price: 65, extra: "9 Colourways", flaws: [], sizing: -2, images: ["https://media.lex.dk/media/5390/standard_Barack_Obama.jpg","https://source.unsplash.com/random/300x300","https://source.unsplash.com/random/300x300","https://source.unsplash.com/random/300x300", "https://source.unsplash.com/random/300x300", "https://source.unsplash.com/random/300x300"]};
    let details = await fetch(API_URL + "/product/" + id).then((res) => res.json());
    console.log(details);
    return details;
}

export async function getHomepage(){
    let info = {new: [], popular: []};

    let newItems = await fetch(API_URL + "/all").then((res) => res.json());
    let popularItems = await fetch(API_URL + "/featured/products").then((res) => res.json());

    info.new = newItems.reverse();
    info.popular = popularItems;

    return info;
}

export async function submit(data){
    let res = await fetch(API_URL + "/submit", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    if(res.status === 200){
        return true;
    }else{
        return false;
    }
}

export async function getBrands(){
    let brands = await fetch(API_URL + "/brands").then((res) => res.json());
    return brands;
}

export async function getShops(){
    let shops = await fetch(API_URL + "/featured/shops").then((res) => res.json());
    return shops;
}

export async function getBrandItems(name){
    let items = await fetch(API_URL + "/brand/" + name).then((res) => res.json());
    return items;
}

export async function getReviewItem(name){
    const token = localStorage.getItem("token");
    let item = await fetch(API_URL + "/review", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token: token})
    }).then((res) => res.json());

    return item;
}

export async function approveItem(data){
    let cock = data;
    cock.approved = true;
    const token = await localStorage.getItem("token");
    if(!token) { return false;}
    cock.token = token;
    console.log(cock);
    fetch(API_URL + "/review", {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(cock)
    });
    return true;
}

export async function rejectItem(data){
    let cock = data;
    cock.approved = false;
    const token = await localStorage.getItem("token");
    if(!token) { return false;}
    cock.token = token;
    console.log(cock);
    fetch(API_URL + "/review", {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(cock)
    });
    return true;
}