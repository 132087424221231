import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import { getBrandItems } from '../Utils/api';
import "./Brand.css";

export default function Brand(){
    const {name} = useParams();
    const [items, setItems] = useState([]);
    useEffect(() => {
        document.title = `${name} | REP.RIP`;
        getBrandItems(name).then((res) => {
            setItems(res);
        });
    }, [name]);
    return (
        <div>
            <Header/>
        <div className="Brand">
            <div className="content">
                <h1 className="head">{name}</h1>
                <br className="divider"/>
                <div className="items">
                    {items.map((item, index) => {
                        return (
                            <a href={"/product/" + item.id} key={index}>
                            <div className="item">
                                <img src={item.images[0]} alt=""/>
                                <h1 className="light">{item.brand}</h1>
                                <h1 className="bold">{item.name}</h1>
                                <h1 className="light">{item.price + "¥"}</h1>
                            </div>
                            </a>
                        )
                    })}
                </div>
            </div>
        </div>
        </div>
    )
}