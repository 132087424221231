import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { getBrands, getShops } from '../Utils/api';
import "./Brands.css"

export default function Brands(){
    const [brands, setBrands] = useState([]);
    const [shops, setShops] = useState([]);
    useEffect(() => {
        document.title = `Brands | REP.RIP`;
        getBrands().then((res) => {
            setBrands(res);
        });
        getShops().then((res) => {
            setShops(res);
        });
    }, []);
    return (
        <div>
            <Header/>
        <div className="Brands">
            <div className="content">
                <h1 className="head">Featured</h1>
                <br className="divider"/>
                <div className="brands fb">
                {shops.map((shop, i) => {
                        return (
                            <a href={shop.url}>
                            <div className="brand featured" key={"shop-" + i}>
                                <h1>{shop.name}</h1>
                                <h1 className="f">FEATURED</h1>
                            </div>
                            </a>
                        )
                    })}
                </div>
                <h1 className="head">Brands</h1>
                <br className="divider"/>
                <div className="brands">
                    {brands.map((brand, i) => {
                        return (
                            <a href={"/brand/" + brand.name}>
                            <div className="brand" key={i}>
                                <h1>{brand.name}</h1>
                                <img src={brand.icon} alt=""/>
                            </div>
                            </a>
                        );
                    })}
                </div>
            </div>
        </div>
        </div>
    );  
}