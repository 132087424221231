import Header from "../components/Header";
import { useState, useEffect } from "react";
import './Review.css';
import { approveItem, getReviewItem, rejectItem } from "../Utils/api";
import { NotificationManager } from "react-notifications";

let data = {
    brands: "",
    name: "",
    url: "",
    type: "",
    sizing: 0,
    extra: "",
    price: 0,
    flaws: "",
    subId: "",
    images: []
}

export default function Review(){

    const [item, setItem] = useState({x: "x"});
    useEffect(() => {
        document.title = `Review ❤️ | REP.RIP`;
        getReviewItem().then((res) => {
            setItem(res);
            data.brands = res.brands || "";
            data.name = res.name || "";
            data.url = res.url || "";
            data.type = res.type || 'apparel';
            data.sizing = res.sizing || 0;
            data.extra = res.extra || "";
            data.price = res.price || 0;
            data.flaws = res.flaws || "";
            data.images = res.images || [];
            data.subId = res._id || "";
        });
    }, []);

    const approve = () => {
        approveItem(data).then((res) => {
            if(res){
                alert("Item approved!");
                window.location.reload();
            }
        });
    }

    const reject = () => {
        rejectItem(data).then((res) => {
            if(res){
                alert("Item rejected!");
                NotificationManager.success("This item was successfully rejected!", "Item Rejected!");
                window.location.reload();
            }
        });
        console.log(data);
    }

    if(localStorage.getItem("token") === null){
        window.location.href = "/";
    }else{
        return (
            <div>
                <Header/>
            <div className="Review">
                <h1 className="head">Admin Review</h1>
                <p className="description">Here you can see all the products that are waiting to be reviewed by us, thank you for being a part of the community and team. ❤️</p>
                <br className="divider"/>
                <div className="items">
                {item === {x: "x"} ? <h1>Loading...</h1> :
                    (
                        <div className="item">
                            <h1 className="light"><a href={item.url} target="_blank" rel="noreferrer">Click here to view item</a></h1>
                            <h1 className="light">Name</h1>
                            <input type="text" name="name" defaultValue={item.name} placeholder="Name" onChange={(e) => data.name = e.target.value} />
                            <h1 className="light">Brand (If multiple, split with commas (,))</h1>
                            <input type="text" name="brand" defaultValue={item.brands} placeholder="Brand" onChange={(e) => data.brands = e.target.value} />
                            <br className="divider"/>
                            <h1 className="light">Product URL</h1>
                            <input type="text" name="url" defaultValue={item.url} placeholder="https://item.taobao.com/item.htm?id=" onChange={(e) => data.url = e.target.value} />
                            <h1 className="light">Product Type</h1>
                            <select defaultValue={item.type || "none"} name="type" onChange={(e) => data.type = e.target.value}>
                                <option value="none">Select one</option>
                                <option value="shoes">Shoes</option>
                                <option value="hoodie">Hoodies/Sweatshirts</option>
                                <option value="pants">Pants/Jeans/cargos</option>
                                <option value="accessories">Accessories</option>
                                <option value="headwear">Headwear</option>
                                <option value="decorations">Decorations</option>
                                <option value="other">Other</option>
                            </select>
                            <br className="divider"/>
                            <h1 className="light">Sizing</h1>
                            <p>**If you need to size up or size down, write how many times should you size up/down?**</p>
                            <input type="number" name="sizing" defaultValue={item.sizing} placeholder="TTS" onChange={(e) => data.sizing = e.target.value} />
                            <h1 className="light">Extra Info</h1>
                            <input type="text" name="extra" defaultValue={item.extra} placeholder="Extra Info" onChange={(e) => data.extra = e.target.value} />
                            <br className="divider"/>
                            <h1 className="light">Price</h1>
                            <input type="number" name="price" defaultValue={item.price} placeholder="Price" onChange={(e) => data.price = e.target.value} />
                            <h1 className="light">Flaws</h1>
                            <input type="text" name="flaws" defaultValue={item.flaws} placeholder="Flaws" onChange={(e) => data.flaws = e.target.value} />
                            <br className="divider"/>
                            <h1 className="light">Images (Split with ,)</h1>
                            <input type="text" name="images" defaultValue={item.images} placeholder="Images" onChange={(e) => data.images = e.target.value.split(",")} />
                            <br className="divider"/>
                            <h1 className="light">Review</h1>
                            <button id="approve" onClick={() => approve()}>Approve</button>
                            <button id="reject" onClick={() => reject()}>Reject</button>
                        </div>
                )}
                </div>
            </div>
            </div>
        )
    }
}