import "./Header.css";
import Logo from "../assets/LogoAss.png";
import { useState } from "react";
import Hamburger from 'hamburger-react'

export default function Header(){
    const [isOpen, setOpen] = useState(false);
    return (
        <div className="Header">
            <div className="logo">
                <a href="/"><img src={Logo} alt=""/></a>
            </div>
            <div className="search">
                {/* <input type="text" placeholder="Search"/> */}
            </div>
            <div className="hb">
                <a href="/settings">SETTINGS</a>
                <a href="/brands">BRANDS</a>
                <a href="/submit">SUBMIT</a>
            </div>
            <div className="hamburger">
                <Hamburger toggled={isOpen} toggle={() => {
                    setOpen(!isOpen);
                    if(isOpen){
                        /*document.querySelector(".hamburger-screen").style.display = "none";*/
                        document.querySelector(".hamburger-screen").classList.remove("active");
                    }else{
                        /*document.querySelector(".hamburger-screen").style.display = "block";*/
                        document.querySelector(".hamburger-screen").classList.add("active");
                    }
                }} size={48} />
                <div className="hamburger-screen">
                    <div className="links">
                        <a href="/submit">SUBMIT</a>
                        <a href="/brands">BRANDS</a>
                        <a href="/settings">SETTINGS</a>
                    </div>
                </div>
            </div>
        </div>
    );
}