import {useState, useEffect } from 'react';
import Header from "../components/Header";
import {getHomepage} from "../Utils/api";
import "./Home.css";

export default function Home(){
    const [products, setProducts] = useState(undefined);

    useEffect(() => {
        document.title = `Home | REP.RIP`;
        getHomepage().then((d) => {
            setProducts(d);
            console.log(d);
        });
    }, []);

    return (
        <div className="Home">
            <Header/>
            <div className="content">
                {products === undefined ? (<></>) : (
                    <div className="pics">
                    <div className="new">
                        <h1 className="bold">Recently Added</h1>
                        <div className="items">
                            {products.new.map((info, index) => {
                                return (
                                    <a href={`/product/${info.id}`}>
                                    <div className="item">
                                        <img src={info.images[0]} alt=""/>
                                    </div>
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                    {(products.popular.length > 0) === false ? <></> : (
                    <div className="popular">
                        <h1 className="bold">Popular</h1>
                        <div className="items">
                            {products.popular.map((info, index) => {
                                return (
                                    <a href={`/product/${info.id}`}>
                                    <div className="item">
                                        <img src={info.images[0]} alt=""/>
                                    </div>
                                    </a>
                                )
                            })}
                        </div>
                    </div>)}
                </div>
                )}
            </div>
        </div>
    );
}