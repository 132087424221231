export function getUserUrl(url){
    let agent = localStorage.getItem("agent") || "pandabuy";
    agent = agent.toLowerCase();
    let _url = "";

    switch(agent){
        case "pandabuy":
            _url = getPandabuyLink(url);
            break;
        case "superbuy":
            _url = getSuperbuyLink(url);
            break;
        default:
            _url = getPandabuyLink(url);
            break;
    }
    // open in new tab

    return _url;
}

export function getPandabuyLink(url){
    return "https://www.pandabuy.com/product?url=" + encodeURIComponent(url);
}

export function getSuperbuyLink(url){
    return "https://www.superbuy.com/en/page/buy/?nTag=Home-search&from=search-input&_search=url&position=&url=" + encodeURIComponent(url);
}