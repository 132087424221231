import Header from "../components/Header";
import { useState, useEffect } from "react";
import './Submit.css';
import { submit } from "../Utils/api";
export default function Submit(){
    const [data, setData] = useState({
        url: "",
        brands: "",
        type: "apparel",
        name: "",
        sizing: 0,
        extra: "",
        flaws: "",
        note: "",
        fantasy: false,
    });

    useEffect(() => {
        document.title = `Submit | REP.RIP`;
    }, []);

    return (
        <div>
            <Header/>
        <div className="Submit">
            <div className="content">
                <h1 className="head">Submit</h1>
                <h2 className="light">Please note that this is not a guarantee that your product will be added.</h2>
                <br className="divider"/>
                <h1 className="light">Product URL</h1>
                <input type="text" placeholder="https://item.taobao.com/item.htm?id=" onChange={(e) => setData({...data, url: e.target.value})}/>
                <br className="divider"/>
                <h1 className="light">Product Brands</h1>
                <input type="text" placeholder="Nike" onChange={(e) => setData({...data, brands: e.target.value})}/>
                <br className="divider"/>
                <h1 className="light">Product Type</h1>
                <select defaultValue="none" onChange={(e) => setData({...data, type: e.target.value})}>
                    <option value="none">Select one</option>
                    <option value="shoes">Shoes</option>
                    <option value="hoodie">Hoodies/Sweatshirts</option>
                    <option value="pants">Pants/Jeans/cargos</option>
                    <option value="accessories">Accessories</option>
                    <option value="headwear">Headwear</option>
                    <option value="decorations">Decorations</option>
                    <option value="other">Other</option>
                </select>
                <br className="divider"/>
                <h1 className="light">Product Name</h1>
                <input type="text" placeholder="Black Phantoms" onChange={(e) => setData({...data, name: e.target.value})}/>
                <br className="divider"/>
                <h1 className="light">Sizing</h1>
                <p>**If you need to size up or size down, write how many times should you size up/down?**</p>
                <input type="number" placeholder="TTS" onChange={(e) => setData({...data, sizing: e.target.value})}/>
                <br className="divider"/>
                <h1 className="light">Extra Info</h1>
                <input type="text" placeholder="Has 9 colourways, long shipping time" onChange={(e) => setData({...data, extra: e.target.value})}/>
                <br className="divider"/>
                <h1 className="light">Is this a fantasy piece? (A piece that doesn't exist as a real item)</h1>
                <input type="checkbox" className="checkbox" onChange={(e) => setData({...data, fantasy: e.target.checked})}/>
                <br className="divider"/>
                <h1 className="light">Flaws (if you find any, if you are not good at QC'ing just leave this empty)</h1>
                <input type="text" placeholder="" onChange={(e) => setData({...data, flaws: e.target.value})}/>
                <br className="divider"/>
                <h1 className="light">Note for review (Will not be shown, but will be told to the admins who will review the product)</h1>
                <input type="text" placeholder="" onChange={(e) => setData({...data, note: e.target.value})}/>
                <br className="divider"/>
                <button onClick={() => {
                    submit(data).then((res) => {
                        if(res){
                            alert("Your product has been submitted!");
                        }else{
                            alert("Your product could not be submitted, are you missing any information?");
                        }
                    });
                }}>Submit</button>
            </div>
        </div>
        </div>
    )
}