import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import "./Product.css"
import Header from "../components/Header";
import { getUserUrl } from "../Utils/links";
import { yuanToCurrency } from "../Utils/currency";
import { getDetails } from "../Utils/api";
import {Helmet} from "react-helmet";

function Product() {
    let { id } = useParams();
    const [details, setDetails] = useState(undefined);
    const [bigImage, setBigImage] = useState(0);
    const [userCurrency, setUserCurrency] = useState(0);
    const unc = localStorage.getItem("currency") || "USD";
    const agent = localStorage.getItem("agent") || "pandabuy";

    function BrandText() {
        if(details === undefined){
            return <></>
        }
        let brands = details.brands;
        return (
            <div className="brandText">
                {brands.map((brand, index) => {
                    return (
                        <a className="brta" href={"/brand/" + brand}>
                            {brand + (index === brands.length - 1 ? "" : ", ")}
                        </a>
                    )
                })}
            </div>
        )
    }
    useEffect(() => {

        getDetails(id).then((d) => {
            document.title = `${d.name} | REP.RIP`;
            setDetails(d);
            console.log(d);
            yuanToCurrency(d.price, unc).then((price) => {
                setUserCurrency(price);
            });
        });
    }, [id, unc])

    if(details === undefined){
        return (
            <div className="Product">
                <Header/>
                <div className="loading">
                    <h1 className="light">Loading...</h1>
                </div>
            </div>
        )
    }

    return (
        <div className="Product">
            <Header/>
            <Helmet>
                <meta property="og:url" content={"http://www.rep.rip/product/" + id} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={details.brands.join(", ") + " - " + details.name} />
                <meta property="og:description" content={
                    details.brands.join(", ") + " - " + details.name + " - " + details.price
                } />
                <meta property="description" content={
                    details.brands.join(", ") + " - " + details.name + " - " + details.price
                } />
                <meta property="og:image" content={details.images[0]} />
            </Helmet>
            <div className="images">
                <div className="bigImage">
                    <img src={details.images[bigImage]} alt=""/>
                </div>
                <div className="small">
                    {details.images.map((image, index) => {
                        return (
                            <div className="smallImage" onClick={() => setBigImage(index)}>
                                <img src={image} alt=""/>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="details">
                <div className="text">
                    <h1 className="light"><BrandText/></h1>
                    <h1 className="bold">{details.name}</h1>
                    <br className="divider"/>
                    <h1 className="light">
                        {details.price}¥ ({userCurrency + " " + unc})<br/>
                        {parseInt(details.sizing) === 0 || !details.sizing ? "TTS" : details.sizing < 0 ? "size DOWN " + Math.abs(details.sizing) + " times" : "size UP " + details.sizing + " times"}<br/>
                        {details.extra}<br/>
                        Id: {id}
                    </h1>
                </div>
                <div className="buttons">
                    <h1 className="bold">Open with</h1>
                    <a href={getUserUrl(details.url)} target="_blank" rel="noreferrer"><button className="agent">{agent}</button></a>
                    <a href={details.url} target="_blank" rel="noreferrer"><button className="buy">Direct Link</button></a>
                </div>
            </div>
        </div>
    );
}

export default Product;